import { gql, useQuery } from "urql";
import useAuth from "./useAuth";
import { User } from "../graphql-types";

export const UserQuery = gql`
  query User($userId: UserId!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      role
      profileImage {
        url
      }
    }
  }
`;

const useUser = () => {
  const { user, isAuthenticated } = useAuth();
  return useQuery<{ user: User }>({
    query: UserQuery,
    variables: {
      userId: user ? { identityProviderId: user.getUsername() } : null,
    },
    pause: !isAuthenticated,
  });
};

export default useUser;
